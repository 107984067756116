import { Theme } from '@emotion/react'
import { playfairDisplay, titilliumWeb } from '@utils/fonts'

export const webthinxTheme: Theme = {
  colors: {
    primary: '#FF7569',
    secondary: '#0F0B48',
    grayDarker: '#EEEEEE',
    grayLighter: '#F4F4F4',
    body: '#666666',
  },
  fonts: {
    headings: playfairDisplay.style.fontFamily,
    body: titilliumWeb.style.fontFamily,
  },
  container: {
    maxWidth: '1290px',
    padding: '10px',
  },
  refSlider: {
    textColumnWidth: {
      mobile: '100%',
      tablet: '40%',
      desktop: '30%',
    },
    deviceColumnWidth: {
      mobile: '100%',
      tablet: '60%',
      desktop: '70%',
    },
  },
}
