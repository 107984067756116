import type { AppProps } from 'next/app'
import { EmotionThemeProvider } from '../themes/EmotionThemeProvider'
import { webthinxTheme } from '../themes/webthinx/theme'
import './global.css'
import { titilliumWeb } from '@utils/fonts'
import { CookieConsent } from '@molecules/CookieConsent/CookieConsent'

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <main className={titilliumWeb.className}>
      <CookieConsent />
      <EmotionThemeProvider theme={webthinxTheme}>
        <Component {...pageProps} />
      </EmotionThemeProvider>
    </main>
  )
}
