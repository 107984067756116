import { Theme, ThemeProvider } from '@emotion/react'
import React from 'react'

type Props = {
  children: React.ReactNode
  theme: Theme
}

export const EmotionThemeProvider: React.FC<Props> = ({ theme, children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
